<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`" :route="$route.name">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view />
    </transition>
    <NewVersion v-model="$root.newVersionExists" />
    <v-snackbar v-model="hasError" color="error" top text>
      {{ errorMsg }}
    </v-snackbar>
    <v-snackbar v-model="hasSuccess" color="success" top text>
      {{ successMsg }}
    </v-snackbar>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted, onBeforeUnmount } from '@vue/composition-api';
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig';
import { useRouter } from '@core/utils';
import { useLayout } from '@core/layouts/composable/useLayout';

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh';

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue';

import events, { LOGIN_REQUIRED, SNACKBAR_FAILURE, SNACKBAR_SUCCESS } from '@/events';
import { AjaxError } from '@axios';

import NewVersion from '@/components/NewVersion.vue';

export default
{
  name: 'App',
  components:
  {
    LayoutContentVerticalNav,
    LayoutBlank,
    NewVersion,
  },
  setup()
  {
    const { route, router } = useRouter();
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig();

    const { handleBreakpointLayoutSwitch } = useLayout();
    handleBreakpointLayoutSwitch();

    const resolveLayoutVariant = computed(() =>
    {
      if (route.value.meta.layout === 'blank') return 'layout-blank';
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`;

      return null;
    });

    const hasError = ref(false);
    const errorMsg = ref('');
    const hasSuccess = ref(false);
    const successMsg = ref('');

    useDynamicVh();
    const hostnameParts = window.location.hostname.split('.');
    const platform = hostnameParts.length > 3 ? hostnameParts[hostnameParts.length - 4] : 'Loopin';
    document.title = `${platform.substr(0, 1).toUpperCase()}${platform.substr(1)} - Dashboard`;

    const gotoLogin = () =>
    {
      // we use an event bus as workaround - if we import "router.js" in "plugins/axios.js" ESLint complains about dependency cycle in Login.vue
      router.push({ name: 'auth-login' });
    };

    const showError = message =>
    {
      if (process.env.NODE_ENV !== 'production')
      {
        if (typeof message !== 'string') console.error(message); // eslint-disable-line no-console
      }
      if (message && !(message instanceof AjaxError && message.message === 'API request was cancelled'))
      { // we must ideally ignore AjaxError's inside Axios CATCH blocks
        errorMsg.value = message;
        hasError.value = true;
      }
    };

    const showSuccess = message =>
    {
      if (message)
      {
        successMsg.value = message;
        hasSuccess.value = true;
      }
    };

    onMounted(() =>
    {
      events.$on(LOGIN_REQUIRED, gotoLogin);
      events.$on(SNACKBAR_FAILURE, showError);
      events.$on(SNACKBAR_SUCCESS, showSuccess);
    });

    onBeforeUnmount(() =>
    {
      events.$off(LOGIN_REQUIRED, gotoLogin);
      events.$off(SNACKBAR_FAILURE, showError);
      events.$off(SNACKBAR_SUCCESS, showSuccess);
    });

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      hasError,
      errorMsg,
      hasSuccess,
      successMsg,
      gotoLogin,
      showError,
      showSuccess,
    };
  },
};
</script>

<style lang="scss">
@font-face
{
  font-family: 'Cereal';
  font-style: normal;
  font-weight: 400;
  src: url('https://www.loopin.ai/assets/fonts/AirbnbCerealBook.ttf') format("truetype");
}

@font-face
{
  font-family: 'Cereal';
  font-style: normal;
  font-weight: 500;
  src: url('https://www.loopin.ai/assets/fonts/AirbnbCerealMedium.ttf') format("truetype");
}

@font-face
{
  font-family: 'Cereal';
  font-style: normal;
  font-weight: 600;
  src: url('https://www.loopin.ai/assets/fonts/AirbnbCerealMedium.ttf') format("truetype");
}

@font-face
{
  font-family: 'Cereal';
  font-style: normal;
  font-weight: 700;
  src: url('https://www.loopin.ai/assets/fonts/AirbnbCerealBold.ttf') format("truetype");
}

#app.v-application.theme--dark .ps__thumb-y
{
  background-color: map-deep-get($material-light, 'cards'); // bug in the dark theme preset for PerfectScrollbar
}
</style>

<style src="@/styles/dragula.scss" lang="scss"></style>
