import {
  mdiCalendarOutline,
  mdiHumanGreeting,
  mdiHandshake,
  mdiTent,
  mdiStore,
  mdiDolly,
  mdiFileSign,
  mdiReceiptTextOutline,
  mdiFilePercent,
  mdiCashMultiple,
  mdiAccountSupervisor,
  mdiPresentationPlay,
} from '@mdi/js';

// Array of sections
export default [
  {
    subheader: 'SALES & PLANNING',
    roles:
      [
        'ROLE_AGENT_SUPERVISOR',
        'ROLE_AGENT',
      ],
  },
  {
    title: 'Deals',
    icon: mdiHandshake,
    to: 'deal-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Calendar',
    icon: mdiCalendarOutline,
    to: 'calendar',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    subheader: 'CONTENT',
    roles:
      [
        'ROLE_AGENT_SUPERVISOR',
        'ROLE_AGENT',
      ],
  },
  {
    title: 'Media kits',
    icon: mdiPresentationPlay,
    to: 'mediakits',
    roles:
      [
        'ROLE_AGENT_SUPERVISOR',
        'ROLE_AGENT',
      ],
  },
  {
    subheader: 'CONTRAGENTS',
    roles:
    [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Creators',
    icon: mdiHumanGreeting,
    to: 'talent-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Brands',
    icon: mdiStore,
    to: 'brand-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Agencies',
    icon: mdiTent,
    to: 'agency-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Managers',
    icon: mdiAccountSupervisor,
    to: 'manager-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    subheader: 'DOCUMENTS',
    roles:
    [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
      'ROLE_ACCOUNTANT',
    ],
  },
  {
    title: 'Contracts',
    icon: mdiFileSign,
    to: 'contract-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Quotations',
    icon: mdiFilePercent,
    to: 'quotation-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    title: 'Invoices',
    icon: mdiReceiptTextOutline,
    to: 'invoice-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
      'ROLE_ACCOUNTANT',
    ],
  },
  {
    subheader: 'ADMIN',
    roles:
      [
        'ROLE_AGENT_SUPERVISOR',
        'ROLE_AGENT',
      ],
  },
  {
    title: 'DeliverableTypes',
    icon: mdiDolly,
    to: 'deliverable-list',
    roles: [
      'ROLE_AGENT_SUPERVISOR',
      'ROLE_AGENT',
    ],
  },
  {
    subheader: 'SUPERVISORS',
    roles:
      [
        'ROLE_AGENT_SUPERVISOR',
      ],
  },
  {
    title: 'PaymentRequests',
    icon: mdiCashMultiple,
    to: 'payment-reqs-list',
    roles:
      [
        'ROLE_AGENT_SUPERVISOR',
      ],
  },
];
