var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "nudge-bottom": "14",
        "min-width": "230",
        "content-class": "user-profile-menu-content",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-badge",
                {
                  staticClass: "ms-4",
                  attrs: {
                    bottom: "",
                    color: "success",
                    overlap: "",
                    "offset-x": "12",
                    "offset-y": "12",
                    dot: "",
                  },
                },
                [
                  _c(
                    "v-avatar",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "v-avatar-light-bg primary--text",
                          attrs: { size: "40px", color: "primary" },
                        },
                        "v-avatar",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.GET_USER_DATA.mediaAvatar
                        ? _c("v-img", {
                            attrs: { src: _vm.GET_USER_DATA.mediaAvatar },
                          })
                        : _c(
                            "v-icon",
                            { attrs: { color: "primary", size: "28" } },
                            [_vm._v(_vm._s(_vm.icons.mdiAccountOutline))]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    bottom: "",
                    color: "success",
                    overlap: "",
                    "offset-x": "28",
                    "offset-y": "20",
                    dot: "",
                  },
                },
                [
                  _c(
                    "v-list-item-avatar",
                    {
                      staticClass: "v-avatar-light-bg primary--text ml-0",
                      attrs: { size: "40px", color: "primary" },
                    },
                    [
                      _vm.GET_USER_DATA.mediaAvatar
                        ? _c("v-img", {
                            attrs: { src: _vm.GET_USER_DATA.mediaAvatar },
                          })
                        : _c(
                            "v-icon",
                            { attrs: { color: "primary", size: "28" } },
                            [_vm._v(_vm._s(_vm.icons.mdiAccountOutline))]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          (
                            _vm.GET_USER_DATA.firstName +
                            " " +
                            _vm.GET_USER_DATA.lastName
                          ).trim() || _vm.GET_USER_DATA.username
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "text-capitalize" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.GET_USER_DATA.roleSimplified) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-2" }),
          _c(
            "v-list-item",
            { on: { click: _vm.showUserSettings } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "me-2" },
                [
                  _c("v-icon", { attrs: { size: "22" } }, [
                    _vm._v(_vm._s(_vm.icons.mdiCogOutline)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t("settings")))])],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-2" }),
          _c(
            "v-list-item",
            { on: { click: _vm.logoutUser } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "me-2" },
                [
                  _c("v-icon", { attrs: { size: "22" } }, [
                    _vm._v(_vm._s(_vm.icons.mdiLogoutVariant)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t("logout")))])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }