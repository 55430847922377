<template>
  <v-dialog v-model="show" scrollable>
    <v-form ref="frm" @submit.prevent="applyCrop">
      <v-card min-width="450" class="d-flex flex-column">
        <v-card-title class="py-2 pr-1 justify-space-between">
          {{ $t('crop_image') }}
          <v-btn icon @click="show = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4 overflow-hidden d-flex flex-column">
          <div class="d-flex flex-column flex-grow-1 overflow-hidden">
            <div class="ma-auto overflow-hidden relative">
              <img ref="img" :src="media" border="0" class="media_preview">
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" type="submit" class="mr-2">{{ $t('crop') }}</v-btn>
          <v-btn outlined @click="show = false">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';
import Cropper from '@/helpers/cropper.esm';

export default
{
  name: 'DialogMediaCrop',
  props:
    {
      value:
        {
          type: Boolean,
          default: false,
        },
      media:
        {
          type: String,
          default: '',
        },
    },
  data()
  {
    return {
      cropper: null,
      icons:
        {
          mdiClose,
        },
    };
  },
  computed:
    {
      show:
        {
          get()
          {
            return this.value;
          },
          set(val)
          {
            this.$emit('input', val);
          },
        },
    },
  watch:
    {
      value(newVal)
      {
        if (newVal)
        {
          this.removeCropper();
          this.$nextTick(() =>
          {
            this.cropper = new Cropper(this.$refs.img,
              {
                viewMode: 2,
                autoCropArea: 1, // crop-handle starts at 100% of the image area
                rotatable: true,
                scalable: true,
                zoomable: true,
                initialAspectRatio: 1,
                aspectRatio: 1,
              });
          });
        }
      },
    },
  beforeDestroy()
  {
    this.removeCropper();
  },
  methods:
    {
      removeCropper()
      {
        if (this.cropper)
        {
          this.cropper.destroy();
          this.cropper = null;
        }
      },
      applyCrop()
      {
        this.cropper.getCroppedCanvas().toBlob(blob =>
        {
          this.$emit('save', blob);
        }, 'image/jpeg', 0.8);
      },
    },
};
</script>

<style src="@/styles/cropper.css"></style>

<style lang="scss">
.media_preview
{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>

<i18n>
{
  "en": {
    "crop_image": "Crop image",
    "crop": "Crop",
    "cancel": "Cancel"
  }
}
</i18n>
