<template>
  <transition name="version">
    <div v-if="value" class="d-inline-flex new_version_alert">
      <v-card color="brown" elevation="4" dark>
        <v-card-title class="pa-3">
          <div class="text-subtitle-1 pr-5">
            {{ $t('new_version', {version: $root.newVersionExists}) }}
          </div>
          <v-spacer />
          <v-btn icon small @click="$emit('input',false)">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions class="justify-center px-3 pb-4 pt-0">
          <v-btn color="success" class="black--text px-5" @click="appRefresh">{{ $t('reload') }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </transition>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default
{
  name: 'NewVersion',
  props:
    {
      value:
        {
          type: String,
          default: '',
        },
    },
  data()
  {
    return {
      opacity: 0,
      mdiClose,
    };
  },
  watch:
    {
      value(newVal)
      {
        this.$nextTick(() =>
        {
          this.opacity = newVal ? 1 : 0;
        });
      },
    },
  methods:
    {
      appRefresh()
      {
        window.location.reload();
      },
    },
};
</script>

<style>
  .version-enter,
  .version-leave-to
  {
    opacity: 0;
    transform: translateY(100%);
  }

  .version-enter-active,
  .version-leave-active
  {
    transition: opacity 0.4s ease-out, transform 0.35s linear;
  }

  .new_version_alert
  {
    z-index: 17;
    position: fixed;
    bottom: 32px;
    left: 16px;
  }

</style>

<i18n>
{
  "en": {
    "new_version": "There is a new version v{version}",
    "reload": "Reload"
  }
}
</i18n>
