var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "frm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.applyCrop.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column",
              attrs: { "min-width": "450" },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "py-2 pr-1 justify-space-between" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("crop_image")) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.icons.mdiClose))])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-4 overflow-hidden d-flex flex-column" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-grow-1 overflow-hidden",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ma-auto overflow-hidden relative" },
                        [
                          _c("img", {
                            ref: "img",
                            staticClass: "media_preview",
                            attrs: { src: _vm.media, border: "0" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary", type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.$t("crop")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }