import { render, staticRenderFns } from "./AccountSettingsAccount.vue?vue&type=template&id=555d6e92&"
import script from "./AccountSettingsAccount.vue?vue&type=script&lang=js&"
export * from "./AccountSettingsAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AccountSettingsAccount.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VFileInput,VForm,VIcon,VImg,VRadio,VRadioGroup,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/builds/loopinai/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('555d6e92')) {
      api.createRecord('555d6e92', component.options)
    } else {
      api.reload('555d6e92', component.options)
    }
    module.hot.accept("./AccountSettingsAccount.vue?vue&type=template&id=555d6e92&", function () {
      api.rerender('555d6e92', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/user-settings/AccountSettingsAccount.vue"
export default component.exports