import { render, staticRenderFns } from "./VerticalNavMenuSectionTitle.vue?vue&type=template&id=1934b2a3&scoped=true&"
import script from "./VerticalNavMenuSectionTitle.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenuSectionTitle.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenuSectionTitle.vue?vue&type=style&index=0&id=1934b2a3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1934b2a3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VIcon,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/builds/loopinai/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1934b2a3')) {
      api.createRecord('1934b2a3', component.options)
    } else {
      api.reload('1934b2a3', component.options)
    }
    module.hot.accept("./VerticalNavMenuSectionTitle.vue?vue&type=template&id=1934b2a3&scoped=true&", function () {
      api.rerender('1934b2a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue"
export default component.exports