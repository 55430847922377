import { render, staticRenderFns } from "./LayoutContentVerticalNav.vue?vue&type=template&id=9203c32e&scoped=true&"
import script from "./LayoutContentVerticalNav.vue?vue&type=script&lang=js&"
export * from "./LayoutContentVerticalNav.vue?vue&type=script&lang=js&"
import style0 from "./LayoutContentVerticalNav.vue?vue&type=style&index=0&id=9203c32e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9203c32e",
  null
  
)

/* custom blocks */
import block0 from "./LayoutContentVerticalNav.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/builds/loopinai/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9203c32e')) {
      api.createRecord('9203c32e', component.options)
    } else {
      api.reload('9203c32e', component.options)
    }
    module.hot.accept("./LayoutContentVerticalNav.vue?vue&type=template&id=9203c32e&scoped=true&", function () {
      api.rerender('9203c32e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue"
export default component.exports