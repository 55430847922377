import pkg from '@/../package.json';

const checkingPeriod = 200; // in seconds

function isNewerVersion(_old, _new)
{
  // return true if SemVersion A is newer than B
  const oldVer = _old.split('.');
  const newVer = _new.split('.');
  if (+oldVer[0] < +newVer[0]) return false;
  if (+oldVer[0] > +newVer[0]) return true;
  if (+oldVer[1] < +newVer[1]) return false;
  if (+oldVer[1] > +newVer[1]) return true;

  return +oldVer[2] > +newVer[2];
}

export default
{
  data()
  {
    return {
      newVersionExists: '',
      timerVersion: null,
      lastVersionCheck: null,
      windowHiddenProp: '',
    };
  },
  watch:
    {
      newVersionExists(newVal, oldVal)
      {
        // if the user decides to dismiss and not refresh - we must continue checking
        if (oldVal && !newVal) this.scheduleVersion();
      },
    },
  methods:
    {
      firstVersionCheck()
      {
        this.lastVersionCheck = Date.now();

        // Set the name of the hidden property and the change event for visibility
        let visibilityChange;
        if (typeof document.hidden !== 'undefined')
        {
          // Opera 12.10 and Firefox 18 and later support
          this.windowHiddenProp = 'hidden';
          visibilityChange = 'visibilitychange';
        }
        else if (typeof document.msHidden !== 'undefined')
        {
          this.windowHiddenProp = 'msHidden';
          visibilityChange = 'msvisibilitychange';
        }
        else if (typeof document.webkitHidden !== 'undefined')
        {
          this.windowHiddenProp = 'webkitHidden';
          visibilityChange = 'webkitvisibilitychange';
        }
        document.addEventListener(visibilityChange, this.handlePageVisibility, false);
        this.scheduleVersion();
      },
      handlePageVisibility()
      {
        if (!document[this.windowHiddenProp])
        {
          // if too much time has passed in the background - immediately check for new version
          if (Date.now() - this.lastVersionCheck > checkingPeriod * 1000)
          {
            if (this.timerVersion) clearTimeout(this.timerVersion);
            this.checkVersion();
          }
        }
      },
      scheduleVersion()
      {
        // check for new versions
        if (this.timerVersion) clearTimeout(this.timerVersion);
        this.timerVersion = setTimeout(this.checkVersion, checkingPeriod * 1000); // check for new version every 3.3 minutes
      },
      checkVersion()
      {
        this.timerVersion = null;
        fetch(`${process.env.BASE_URL}index.html`).then(response => response.text()).then(t =>
        {
          this.lastVersionCheck = Date.now();
          const newVersion = t.match(/<template id="?VERSION"?>([^<]+)<\/template>/);
          if (newVersion[1])
          {
            if (isNewerVersion(newVersion[1], pkg.version))
            {
              this.newVersionExists = newVersion[1]; // eslint-disable-line prefer-destructuring
            }
            else this.scheduleVersion();
          }
          else this.scheduleVersion();
        }).catch(err =>
        {
          console.error('Could not check for new version', err.message || err);
          this.scheduleVersion();
        });
      },
    },
};
