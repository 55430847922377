var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "version" } }, [
    _vm.value
      ? _c(
          "div",
          { staticClass: "d-inline-flex new_version_alert" },
          [
            _c(
              "v-card",
              { attrs: { color: "brown", elevation: "4", dark: "" } },
              [
                _c(
                  "v-card-title",
                  { staticClass: "pa-3" },
                  [
                    _c("div", { staticClass: "text-subtitle-1 pr-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("new_version", {
                              version: _vm.$root.newVersionExists,
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("input", false)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "justify-center px-3 pb-4 pt-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "black--text px-5",
                        attrs: { color: "success" },
                        on: { click: _vm.appRefresh },
                      },
                      [_vm._v(_vm._s(_vm.$t("reload")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }